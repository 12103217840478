<template>
  <section>
    <section class="search-container">
      <a-form
        :form="warmForm"
        layout="inline"
        @submit="submitForm"
        class="m-t-14"
      >
        <a-form-item label="预警设备">
          <a-input v-decorator="['deviceName']" placeholder="请输入" />
        </a-form-item>

        <a-form-item label="预警时间">
          <a-range-picker v-decorator="['time']">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-item>
        <a-form-item label="预警等级">
          <a-select class="search-select" placeholder="请选择" v-decorator="['alarmGrade']">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="红色预警">
              红色预警
            </a-select-option>
            <a-select-option value="橙色预警">
              橙色预警
            </a-select-option>
            <a-select-option value="黄色预警">
              黄色预警
            </a-select-option>
            <a-select-option value="蓝色预警">
              蓝色预警
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="状态">
          <a-select   class="search-select" placeholder="请选择" v-decorator="['dealStatus']">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="0">
              未处理
            </a-select-option>
            <a-select-option value="1">
              已处理
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit">查询</a-button>
          <a-button @click="reset">重置</a-button>
        </a-form-item>
      </a-form>
    </section>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        :columns="list.columns"
        @change="changePageNum"
        :pagination="{ showQuickJumper: false, total: list.total }"
      >
        <template slot="dealStatus" slot-scope="list, text">
          <span>{{ text.dealStatus == "0" ? "未处理" : "已处理" }}</span>
        </template>
        <template slot="operation" slot-scope="list, text">
          <a style="color:#529aff" @click="checkFile(text.id)">详情</a>
        </template>
      </a-table>
    </section>

    <a-drawer
      class="drawer_module"
      title="设备预警详情"
      placement="right"
      :visible="deviceVisible"
      :width="350"
      @close="onClose"
      :maskClosable="true"
    >
      <!-- 进度状态 -->
      <section>
        <section class="deviceData">
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              预警名称
            </a-col>
            <span>{{ alarmRecordData.alarmName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              事件描述
            </a-col>
            <span>{{ alarmRecordData.alarmMessage }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              预警设备
            </a-col>
            <span>{{ alarmRecordData.deviceName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              预警等级
            </a-col>
            <span>{{ alarmRecordData.alarmGrade }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              预警时间
            </a-col>
            <span>{{ alarmRecordData.createTime }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              所属区域
            </a-col>
            <span>{{ alarmRecordData.alarmArea }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              状态
            </a-col>
            <span>{{
              alarmRecordData.dealStatus == "0" ? "未处理" : "已处理"
            }}</span>
          </a-row>

          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              预警时长
            </a-col>
            <span>{{alarmRecordData.unProcessedDayNum}}天</span>
          </a-row>

           <a-row v-if="alarmRecordData.dealStatus!=0" class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color:#4B80CB">
              处理时间
            </a-col>
            <span>{{alarmRecordData.updateTime}}</span>
          </a-row>

           <a-row class="form-btn-group">
            <a-button class="close_button" @click="deviceVisible = false"
              >关闭</a-button
            >
          </a-row>
        </section>
      </section>

      <div
        v-if="alarmRecordData.dealStatus == '0'"
        :style="{
          position: 'absolute',
          right: '10px',
          bottom: '20px',
          width: '100%',
          padding: '10px 16px',
          textAlign: 'right',
          zIndex: 1
        }"
      >
        <a-button
          :style="{
            marginRight: '8px',
            backgroundColor: '#1c3d6c',
            border: 'none'
          }"
          type="primary"
          @click="save"
        >
          确认处理
        </a-button>
        <a-button @click="close">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </section>
</template>
<script>
export default {
  data() {
    return {
      list: {
        columns: [
          { title: "序号", dataIndex: "key", align: "center" },
          { title: "预警名称", dataIndex: "alarmName", align: "center" },
          { title: "事件描述", dataIndex: "alarmMessage", align: "center" },
          { title: "预警设备", dataIndex: "deviceName", align: "center" },
          { title: "预警等级", dataIndex: "alarmGrade", align: "center" },
          { title: "预警时间", dataIndex: "createTime", align: "center" },
          { title: "所属区域", dataIndex: "alarmArea", align: "center" },
          {
            title: "预警时长",
            dataIndex: "unProcessedDayNum",
            align: "center"
          },
          {
            title: "状态",
            dataIndex: "dealStatus",
            align: "center",
            scopedSlots: { customRender: "dealStatus" }
          },
          {
            title: "操作",
            dataIndex: "operation",
            align: "center",
            scopedSlots: { customRender: "operation" }
          }
        ],
        data: [],
        pageNum: 1,
        total: 0
      },
      dealStatus: "",
      alarmGrade: "",
      deviceName: "",
      deviceVisible: false,
      endDate: "",
      startDate: "",
      alarmRecordData: {},
      warmForm: this.$form.createForm(this),
      dealStatusId: ""
    };
  },
  created() {
    this.alarmRecordList();
  },
  methods: {
    alarmRecordList() {
      let params = {
        dealStatus: this.dealStatus,
        current: this.list.pageNum,
        alarmGrade: this.alarmGrade,
        deviceName: this.deviceName,
        startDate: this.startDate,
        endDate: this.endDate
      };
      this.$api.alarmRecordList(params).then(res => {
        if (res.code == "200") {
          console.log(res);
          this.list.total = res.data.total ? res.data.total : 0;
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.key = index + 1;
            });
          }
          this.list.data = res.data.records;
          console.log(this.list.data);
        }
      });
    },
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.warmForm.getFieldsValue();
      console.log(formVal);
      this.dealStatus = formVal.dealStatus;
      this.alarmGrade = formVal.alarmGrade;
      this.deviceName = formVal.deviceName;
      this.startDate = formVal.time
        ? formVal.time[0].format("YYYY-MM-DD")
        : "";
      this.endDate = formVal.time
        ? formVal.time[1].format("YYYY-MM-DD")
        : "";
      this.list.pageNum = 1;
      this.alarmRecordList();
    },
    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.alarmRecordList();
    },
    // 重置
    reset() {
      this.dealStatus = "";
      this.alarmGrade = "";
      this.deviceName = "";
      this.startDate = "";
      this.endDate = "";
      this.list.pageNum = 1;
      this.warmForm.resetFields();
      this.alarmRecordList();
    },
    // 查看详情  alarmRecordDetail
    checkFile(id) {
      this.$api.alarmRecordDetail(id).then(res => {
        if (res.code == "200") {
          this.alarmRecordData = res.data ? res.data : {};
          this.dealStatusId = id;
        }
      });
      this.deviceVisible = true;
    },
    // 确认处理
    save() {
      console.log(this.dealStatusId);
      let params = {
        dealStatus: "1",
        id: this.dealStatusId
      };
      this.$api.handerRecord(params).then(res => {
        if (res.code == "200") {
          this.deviceVisible = false;
          this.alarmRecordList();
        }
      });
    },
    // 关闭
    close() {
      this.deviceVisible = false;
    },
    // 关闭
    onClose() {
      this.deviceVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
</style>